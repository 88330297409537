<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:24:13
 * @LastEditTime: 2023-03-13 17:24:23
 * @Descripttion: 【数据统计】教学统计
-->
<style lang="scss" scoped>
.teacher {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-right: 20px;

    &-wrapper {
        @include innerPage(28px 20px 48px 15px);
        @include pageTitle(0, 0, flex-start);
    }

    &-level--wrapper {
        width: 90%;
        min-width: 1200px;
        box-sizing: border-box;
        padding-left: 14px;
        margin: 18px 0 64px;
        @include flexBox;

        li {
            width: 19%;
            height: 188px;
            max-width: 278px;
            min-width: 220px;
            border-radius: 25px;
            box-sizing: border-box;
            padding: 52px 32px 0;
            background: rgba($color: #fed045, $alpha: 0.07);
            margin-right: 1.25%;
            &:last-child{
                margin-right: 0;
            }

            img {
                margin-left: -5px;
            }

            p {
                line-height: 30px;
                font-size: 16px;
                color: #505050;
                @include flexBox(space-between);

                span {
                    margin-left: auto;
                    font-size: 24px;
                }
            }

            &:nth-child(2) {
                background: rgba($color: #ffab44, $alpha: 0.07);
            }

            &:nth-child(3) {
                background: rgba($color: #fe4554, $alpha: 0.07);
            }

            &:nth-child(4) {
                background: rgba($color: #7745fe, $alpha: 0.07);
            }

            &:nth-child(5) {
                background: rgba($color: #00b562, $alpha: 0.07);
            }
        }
    }

    .page-title .search-form {
        margin-left: 72px;
    }

    .img-title {
        box-sizing: border-box;
        padding-left: 14px;
        @include flexBox;

        img {
            width: 78px;
        }

        p {
            box-sizing: border-box;
            padding-top: 14px;
            margin-left: 18px;
            font-size: 16px;
            color: #484848;

            span {
                font-size: 30px;
                color: #6c4ecb;
            }
        }
    }

    .statistics {
        &-wrapper {
            width: 97.43%;
            max-width: 1520px;
            min-width: 1000px;
            margin: 38px 0 64px;
            @include flexBox(space-between);
        }

        &-rate,
        &-trend {
            width: 49%;
            max-width: 746px;
            min-width: 490px;
            border-radius: 10px;
            background: #f6f7fa;
            height: 464px;

            .label {
                font-size: 16px;
                line-height: 24px;
                color: #535353;
            }
        }


        &-rate {
            position: relative;
            @include flexBox;

            &--item {
                width: 50%;
                box-sizing: border-box;
                padding: 116px calc((50% - 200px) / 2) 0;
                position: relative;

                .center-text {
                    width: 200px;
                    height: 200px;
                    position: absolute;
                    left: 50%;
                    top: 116px;
                    flex-direction: column;
                    transform: translateX(-50%);
                    @include flexBox(center);

                    .percentage {
                        font-size: 36px;
                        line-height: 1em;
                        color: #6340c8;

                        span {
                            font-size: 14px;
                        }

                        &.success {
                            color: #28ca96;
                        }

                    }
                }

                .label {
                    margin-top: 68px;
                    text-align: center;
                }
            }

            &::after {
                content: '';
                height: 100%;
                border-left: 1px solid rgba($color: #cbcbcb, $alpha: .24);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &-trend {
            &--echarts {
                width: 100%;
                height: calc(100% - 80px);
            }

            .label {
                padding-left: 72px;
                padding-right: 70px;
                margin-top: 24px;
                @include flexBox(space-between);

                .legend {
                    font-size: 14px;

                    i {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 2px;
                        margin-right: 8px;
                        margin-left: 18px;

                        &.success {
                            background: #2ac293;
                        }

                        &.primary {
                            background: #6c4ecb;
                        }
                    }
                }
            }
        }
    }

    .share-courseware {
        box-sizing: border-box;
        padding-left: 8px;
        margin-top: 40px;

        .img-title {
            margin-left: -6px;
        }

        .table {
            &-box {
                width: 926px;
                box-sizing: border-box;
                margin-top: 42px;

                .avatar {
                    width: 58px;
                    height: 58px;
                    border-radius: 50%;

                    .el-avatar {
                        border: 1px solid #fff;
                        border-radius: 50%;
                    }

                    &.top-three {
                        width: 68px;
                        height: 70px;
                        border-radius: 0;
                        border: none;
                        padding-left: 10px;
                        padding-top: 12px;
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        box-sizing: border-box;
                    }

                    &.ranking-level {
                        &--1 {
                            background-image: url("~@assets/images/ranking-1st.png");
                        }

                        &--2 {
                            background-image: url("~@assets/images/ranking-2nd.png");
                        }

                        &--3 {
                            background-image: url("~@assets/images/ranking-3rd.png");
                        }
                    }
                }
            }

            &-head {
                height: 85px;
                background: #dee1ef;
                border-radius: 15px;
                margin-bottom: 10px;
                @include flexBox;

                h5 {
                    line-height: 50px;
                    color: #363636;
                    text-align: center;
                }
            }

            &-row {
                height: 85px;
                border-radius: 15px;
                overflow: hidden;
                background: #eff3f9;
                margin-bottom: 10px;
                color: #464646;
                @include flexBox;

                &:hover {
                    transform: translateX(65px);
                    background: #6c4ecb;
                    color: #fff;
                }
            }

            &-cell {
                width: 100%;

                * {
                    @include flexBox(center);
                }

                p {
                    margin: 0 auto;
                }

                &:nth-child(1) {
                    width: 20.95%;
                }

                &:nth-child(2) {
                    width: 17.71%;
                }

                &:nth-child(3) {
                    width: 35.31%;
                }

                &:nth-last-child(1) {
                    width: 26.03%;
                }
            }
        }
    }


}
</style>

<template>
    <section class="teacher-wrapper">
        <div class="teacher">
            <div class="img-title">
                <img src="@assets/images/bitmap-home.png" alt="">
                <p>教师总数量：<span class="pf_bold">{{ statisticsData.teacher_data.teacher_count || 0 }}</span> 人</p>
            </div>
            <ul class="teacher-level--wrapper">
                <li v-for="(item, index) in statisticsData.teacher_data.teacher_level.slice(0, 5)" :key="item.teuse_lev_id">
                    <img v-if="index == 0" src="@assets/images/teacher-level1.png" alt="">
                    <img v-if="index == 1" src="@assets/images/teacher-level2.png" alt="">
                    <img v-if="index == 2" src="@assets/images/teacher-level3.png" alt="">
                    <img v-if="index == 3" src="@assets/images/teacher-level4.png" alt="">
                    <img v-if="index == 4" src="@assets/images/teacher-level5.png" alt="">
                    <p class="bold">
                        {{ item.teuse_lev_title }}<span class="pf_bold">{{ item.teacher_count || 0 }}</span>人
                    </p>
                </li>
            </ul>
            <div class="page-title">
                <h3>备课统计</h3>
                <div class="search-form">
                    <el-form inline>
                        <el-form-item>
                            <el-select v-model="teacherId" placeholder="选择教师" @change="getStatistics">
                                <el-option label="全部" :value="0" />
                                <el-option v-for="item in teacherList" :key="item.teuser_id" :label="item.teuse_name"
                                    :value="item.teuser_id" />
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="statistics-wrapper">
                <ul class="statistics-rate">
                    <li class="statistics-rate--item">
                        <el-progress class="percentage-progress success" :width="200" :stroke-width="30" type="circle"
                            stroke-linecap="butt" :show-text="false"
                            :percentage="statisticsData.lesson_data.teacher_rate || 0" />
                        <div class="center-text">
                            <p class="pf_bold percentage success">
                                {{ statisticsData.lesson_data.teacher_rate || 0 }}
                                <span class="pf">%</span>
                            </p>
                        </div>
                        <p class="label">备课率</p>
                    </li>
                    <li class="statistics-rate--item">
                        <el-progress class="percentage-progress" :width="200" :stroke-width="30" type="circle"
                            stroke-linecap="butt" :show-text="false"
                            :percentage="statisticsData.lesson_data.teacher_complete_rate || 0" />
                        <div class="center-text">
                            <p class="pf_bold percentage">
                                {{ statisticsData.lesson_data.teacher_complete_rate || 0 }}
                                <span class="pf">%</span>
                            </p>
                        </div>
                        <p class="label">备课完整率</p>
                    </li>
                </ul>
                <div class="statistics-trend">
                    <div class="statistics-trend--echarts" ref="trend"></div>
                    <div class="label">
                        <p>全部教师近七日备课情况趋势图</p>
                        <p class="legend">
                            <i class="success"></i>
                            <span>备课率</span>
                            <i class="primary"></i>
                            <span>备课完整率</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="page-title">
                <h3>共享课件统计</h3>
            </div>
            <div class="share-courseware">
                <div class="img-title">
                    <img src="@assets/images/bitmap-home.png" alt="">
                    <p>共享课件数量：<span class="pf_bold">{{ statisticsData.ranking_data.courseware_count || 0 }}</span> 个</p>
                </div>
                <div class="table-box">
                    <div class="table-head">
                        <h5 style="width: 20.95%">排行</h5>
                        <h5 style="width: 17.71%">头像</h5>
                        <h5 style="width: 35.31%">教师姓名</h5>
                        <h5 style="width: 26.03%">共享数量</h5>
                    </div>
                    <div class="table-body" ref="tableBox">
                        <ul class="table-row pf" v-for="(item, index) in statisticsData.ranking_data.courseware_ranking"
                            :key="item.teuser_id">
                            <li class="table-cell">
                                <h4 class="pf_bold">{{ index + 1 }}</h4>
                            </li>
                            <li class="table-cell">
                                <p class="avatar" :class="[{ 'top-three': index < 3 }, `ranking-level--${index + 1}`]">
                                    <el-avatar :size="58" :src="formatfile(item.teuse_image)">
                                        <img src="@assets/images/empty_avatar.png" />
                                    </el-avatar>
                                </p>
                            </li>
                            <li class="table-cell">
                                <p>{{ item.teuse_name }}</p>
                            </li>
                            <li class="table-cell">
                                <p>{{ item.sce_count || 0 }}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { $teacherAnalysis } from "@api/statistics"
import { $schoolTeachers } from "@api/common"
import { formatFile } from "@utils"
export default {
    name: 'statistics_teacher',
    computed: {
        formatfile() {
            return function (url) {
                return formatFile(url);
            }
        }
    },
    data() {
        return {
            teacherId: 0,
            teacherList: [],
            statisticsData: {},
        }
    },
    created() {
        this.getTeachers();
        this.getStatistics();
    },
    methods: {
        /** 获取学校下所有教师 */
        async getTeachers() {
            let { data } = await $schoolTeachers();
            this.teacherList = [...data] || [];
        },
        /** 获取教师统计数据 */
        async getStatistics() {
            let { data } = await $teacherAnalysis(this.teacherId);
            this.statisticsData = { ...data };
            this.$nextTick(() => {
                let { chart_data: chart } = data;
                let xAxisData = chart.x_data || []
                let seriesData = chart.y_data.day_rate || []
                let seriesData2 = chart.y_data.day_complete_rate || []
                this.trendEcharts(xAxisData, seriesData, seriesData2);
            });
            this.$forceUpdate();
        },
        /** 成绩趋势图表 */
        trendEcharts(xAxisData, seriesData, seriesData2) {
            let echarts = this.$echarts.init(this.$refs.trend);
            echarts.resize();
            echarts.clear();
            let option = {
                tooltip: {
                    trigger: 'axis',
                    extraCssText: 'border-radius: 8px',
                    padding: [5, 10],
                    formatter: function (a) {
                        let list = []
                        let listItem = ''
                        for (var i = 0; i < a.length; i++) {
                            list.push(
                                `<p style="padding: 5px 0;" >
                                    <i style="display: inline-block;width: 8px;height: 8px;background:${a[i].color};border-radius: 2px;margin-right: 6px"></i>
                                    <span style="display: inline-block">${a[i].data}%</span>
                                </p>`
                            )
                        }
                        listItem = list.join('')
                        return `<div style="padding:6px;">${listItem}</div>`
                    },
                    textStyle: {
                        color: '#535353',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(152, 126, 232, 0.26)'
                        }
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData,
                    offset: 18,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    nameTextStyle: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    offset: 5,
                    max: 100,
                    min: 0,
                    interval: 10,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: ['rgba(233,233,244,0.5)'],
                            type: 'dashed'
                        }
                    },
                    nameTextStyle: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                grid: {
                    show: false,
                    bottom: '12%',
                },
                series: [
                    {
                        type: 'line',
                        name: '备课率',
                        data: seriesData,
                        symbol: 'circle',
                        showSymbol: false,
                        symbolSize: 10,
                        itemStyle: {
                            color: '#2ac293',
                            borderWidth: 2,
                            borderColor: '#fff',
                            shadowColor: 'rgba(42, 194, 147, 0.53)',
                            shadowBlur: 20
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: `rgba(42, 194, 147, 0.8)`
                                },
                                {
                                    offset: 1,
                                    color: `rgba(42, 194, 147, 0)`
                                }
                            ])
                        },
                    },
                    {
                        type: 'line',
                        name: '备课完整率',
                        data: seriesData2,
                        symbol: 'circle',
                        showSymbol: false,
                        symbolSize: 10,
                        itemStyle: {
                            color: '#6340c8',
                            borderWidth: 2,
                            borderColor: '#fff',
                            shadowColor: 'rgba(99, 64, 200, 0.53)',
                            shadowBlur: 20
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: `rgba(152, 126, 232, 0.8)`
                                },
                                {
                                    offset: 1,
                                    color: `rgba(152, 126, 232, 0)`
                                }
                            ])
                        },
                    },
                ],
            };
            echarts.setOption(option);
        },
    },
}
</script>
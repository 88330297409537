/*
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:54:50
 * @LastEditTime: 2023-03-13 09:57:48
 * @Descripttion: 数据统计模块接口
 */
import HTTP from "@utils/http";

/*******
 * @Descripttion: 学情统计
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $studyAnalysis(params) {
  try {
    return await HTTP.post("/school/stuuser/study_analysis", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 教师统计
 * @Author: Dyf
 * @param {number} teuser_id 教师id
 * @return {*}
 */
export async function $teacherAnalysis(teuser_id) {
  try {
    if (teuser_id) return await HTTP.post("/school/teauser/teacher_analysis", { teuser_id });
    return await HTTP.post("/school/teauser/teacher_analysis");
  } catch (error) {
    return error;
  }
}
